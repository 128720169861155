@use '@angular/material' as mat;
@use './assets/scss/colors' as *;

@import url('https://cdn.easi-training.fr/assets/css/icons.css');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,400italic,600,700');

@include mat.elevation-classes();
@include mat.app-background();

html,
body {
    font-size: 14px;
    margin: 0px;
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
    z-index: -1;

    @include mat.theme(
        (
            color: (
                theme-type: light,
                primary: mat.$azure-palette,
                tertiary: mat.$blue-palette
            ),
            typography: 'Source Sans Pro',
            density: 0
        )
    );
}

* {
    font-family: 'Source Sans Pro', sans-serif;
}

.ngx-bar {
    top: 50px !important;
    background: #ff3366 !important;
}

.content {
    :has(app-import-ypareo) {
        padding: 0 0 0 0 !important;
    }
}

/********* EDITOR WYSIWYG **********/

.mce-notification,
.mce-statusbar {
    display: none !important;
}

.fiche {
    .mce-tinymce,
    .mce-stack-layout,
    .mce-edit-area {
        display: flex;
        flex: 1;
    }
    .mce-container {
        border: none;
        box-shadow: none;
        position: relative;
        background: transparent;
        height: 100%;

        .mce-top-part {
            position: absolute;
            top: -34px;
            right: -64px;
            &:before {
                box-shadow: none !important;
            }
            > .mce-container-body {
                min-width: 510px;
                background: white;
            }
        }
        .mce-edit-area {
            border: none;
        }
    }
    .hidden {
        .mce-top-part {
            display: none;
        }
    }
}

.recommandedConfiguration {
    border-collapse: collapse;
    text-align: center;
    width: 100%;
    margin: auto;
    margin-top: 30px;
    line-height: 30px;
    font-size: 16px;
    td {
        border: 1px solid white;
    }
    .sub_title {
        background-color: $blue1;
        color: white;
        font-weight: bold;
        a {
            color: white;
        }
    }
    .blue_line {
        background-color: aliceblue;
    }
    .white_line {
        background-color: white;
    }
}

.input {
    text-transform: unset !important;
    font-weight: inherit !important;
}

/********** TOOLTIPS **********/

.tooltip-container {
    background-color: $blue5 !important;
    border-color: $blue5 !important;
    color: white !important;
}

/********** SCROLLBAR **********/
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-button:start:decrement,
::-webkit-scrollbar-button:end:increment {
    display: block;
    height: 0;
    background-color: transparent;
}

::-webkit-scrollbar-track-piece {
    //	background-color: $grey3;
    border-radius: 8px;
}

::-webkit-scrollbar-thumb {
    height: 50px;
    background-color: $grey2;
    border-radius: 8px;
    z-index: 9999;
}
