@use './colors' as *;
@use '@angular/material' as mat;

/* Global overrides */

:root {
    @include mat.button-overrides(
        (
            filled-container-color: $blue3,
            filled-label-text-color: white,
            filled-label-text-transform: uppercase,
            filled-label-text-size: 14px
        )
    );
}

.cdk-overlay-backdrop {
    z-index: 999 !important;
}

.cdk-global-overlay-wrapper {
    pointer-events: auto !important;
    overflow: auto !important;
}

.mat-mdc-dialog-container {
    margin: 0 !important;
    padding: 0 !important;
    overflow: visible !important;
    .mdc-dialog__surface {
        overflow: visible !important;
        .mat-mdc-dialog-component-host {
            .header {
                background-color: $blue3;
                color: $white;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 40px;

                [class^='icon-'] {
                    font-size: 24px;
                    padding: 8px;
                }
                .label {
                    flex-grow: 1;
                    text-align: center;
                    text-transform: uppercase;
                    font-weight: bold;
                }
                mat-icon {
                    color: $white;
                }
            }
        }
        .mat-mdc-dialog-content {
            letter-spacing: unset !important;
            line-height: unset !important;
            font-size: unset !important;
            font-weight: unset !important;
        }
        .footer {
            background-color: $blue3;
            color: $white;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            padding: 10px;
            .mdc-button {
                width: 100%;
                background-color: $white;
                color: $blue3;
                border-radius: 5px;
                text-transform: uppercase;
                font-size: 14px;
            }
        }
    }
}

.mat-expansion-panel-header {
    padding: 0px 24px 0px 0px !important;
    &:hover {
        background: initial !important;
    }
}

mat-option {
    .mdc-list-item__primary-text {
        display: flex;
        align-items: center;
        gap: 10px;
        span[class^='icon-'] {
            font-size: 24px;
        }
    }
}

/* Specific overrides */

app-import-ypareo {
    .mat-mdc-form-field-subscript-wrapper {
        display: none;
    }
    .mat-expansion-panel {
        --mat-expansion-header-indicator-color: white;

        background: $blue3;
        box-shadow: none !important;
        border-radius: 0 !important;

        .tm-description {
            color: white;
        }
        h1 {
            color: white;
            width: 100%;
        }
        form {
            padding: 15px 30px;
            background: white;
        }
    }
}

app-users-ypareo {
    mat-label {
        display: flex;
        align-items: center;
        gap: 10px;
    }
    mat-icon {
        font-size: 24px !important;
    }
}
