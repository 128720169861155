@use './colors' as *;

// Colors
.tm-color-success {
    color: $green1 !important;
}
.tm-color-error {
    color: $red1 !important;
}
.tm-color-grey {
    color: $grey1 !important;
}

.tm-text-center {
    text-align: center;
}

// Flex
.tm-flex {
    display: flex;
}
.tm-flex-row {
    flex-direction: row;
}
.tm-flex-column {
    flex-direction: column;
}
.tm-justify-between {
    justify-content: space-between;
}
.tm-justify-end {
    justify-content: flex-end;
}
.tm-align-center {
    align-items: center;
}
.tm-column-expand {
    flex: 1 1 0;
}

// Height
.tm-height-100 {
    height: 100%;
}

// Section
.tm-section {
    padding-block: 2em;
}

// Gap
.tm-gap-0\.5 {
    gap: 0.5em;
}
.tm-gap {
    gap: 1em;
}
.tm-gap-2 {
    gap: 2em;
}

// Margin
.tm-mb {
    margin-bottom: 1em;
}
.tm-mb-2 {
    margin-bottom: 2em;
}

// Form and fields
.tm-input-field {
    width: 100%;
    outline: none;
    border: 2px solid rgba(0, 0, 0, 0.1);
    font-size: 14px;
    padding: 4px 6px;
    box-sizing: border-box;
}
.tm-icon {
    font-size: 2.5em;
}
.tm-button {
    padding: 8px 20px;
    box-sizing: border-box;
    background: $blue3;
    color: white;
    cursor: pointer;
    border-radius: 4px;
    border: 0;
    text-transform: uppercase;
    position: relative;

    &.tm-button--red {
        background: $rose1 !important;
    }

    &.tm-button--success {
        background: $grey2 !important;
    }
    &.tm-button--error {
        background: $rose1 !important;
    }
    &[disabled] {
        cursor: not-allowed;
        background: $grey2 !important;
    }
    &.tm-color-success {
        cursor: default;
    }

    &:has(> .icon:first-child:last-child) {
        padding: 0;
        border-radius: 0;
        color: inherit;
        background: none;
        display: inline-flex;
    }

    &.tm-button--processing {
        background: $orange3;
        .tm-button-text {
            visibility: hidden;
        }
        .tm-loading-icon {
            display: inline-block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            --_g: no-repeat radial-gradient(circle closest-side, #fff 90%, #fff0);
        }
    }
}
